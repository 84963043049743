@font-face {
  font-family: 'RNS Sanz';
  src: url('assets/fonts/RNSSanz-Light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'RNS Sanz';
  src: url('assets/fonts/RNSSanz-Normal.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'RNS Sanz';
  src: url('assets/fonts/RNSSanz-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}


@font-face {
  font-family: 'RNS Sanz';
  src: url('assets/fonts/RNSSanz-SemiBold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'RNS Sanz';
  src: url('assets/fonts/RNSSanz-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'RNS Sanz';
  src: url('assets/fonts/RNSSanz-ExtraBold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'RNS Sanz';
  src: url('assets/fonts/RNSSanz-Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}
